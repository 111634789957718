import React, {useEffect, useState} from "react"
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import {config} from '../../components/Common/constant';

import MarketingCampaign from '../../components/modules/marketing_campaign';

import { GET_ARTICLE } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {

    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('pre-launch-marketing-campaign');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        <div className="pre-wrapper">

        <Header />

        <MarketingCampaign presentation="custom" property_data={property_data} />

        <NavLink tab_name="Marketing Campaign" prevLink={config.custom_comparables}  nextLink={config.custom_fees} />

        </div>
    </>
}

export default PresentationPage